import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ReactPlayer from 'react-player'
import Webcam from "react-webcam";
import { playPlaylistVideo, webcamUpload, viewedVideo, eventVideo, start_at } from '../../actions/watchActions';
import "./styles_play.css";
import { translate } from '../../components/Translate'

const videoConstraints = {
    width: 320,
    height: 320,
    facingMode: "user"
};
var autoLogout;

export default class Play extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false,
            ended: false,
            canPlay: false,
            processing: false,
            urlVideo: null,
            isLoading: true,
            videoAvailable: false,
            loadedVideo: 0,
            iframeUrl: null,
            current_video: 0,
            infoData: {},
            videos: [],
        }
        if (this.props.study_id) {
            this.study_id = this.props.study_id;
        }

        this.webcam = null;
        this.screenshots = [];
        this._onProgress = this._onProgress.bind(this);
        this._onEnded = this._onEnded.bind(this);
        this._startVideo = this._startVideo.bind(this);
        this._onUserMedia = this._onUserMedia.bind(this);
        autoLogout = setTimeout(function () {
            window.location.reload();
        }, 60000);
    }

    setRef = webcam => {
        this.webcam = webcam;
    };

    capture = () => {
        const imageSrc = this.webcam.getScreenshot();
        if (imageSrc != null) {
            this.screenshots.push(imageSrc);
        }
    };

    cleanUrl(url, show_iframe = false) {
        let post = { "study_video_id": this.study_video_id };
        let tags = url.match(/({{[a-z_0-9]+}})/gi);
        let query_string = window.location.search.replaceAll("%3C", "").replaceAll("%3E", "").replaceAll('(', '').replaceAll(')', '');
        let params = new URLSearchParams(query_string);

        for (var pair of params.entries()) {
            post[pair[0]] = pair[1];
        }

        tags.map((val) => {
            url = url.replace(val, params.get(val.match(/[a-z_0-9]+/)[0]));
        });
        const redirect_url = new URL(url)

        let searchParams = new URLSearchParams(redirect_url.search);

        if (show_iframe) {
            console.log(url);
            this.setState({
                iframeUrl: url,
            });
        } else {

            for (var pair of searchParams.entries()) {
                post[pair[0]] = pair[1];
            }
            eventVideo(post).then(() => {
                window.location.href = url;
            });
        }
    }

    async componentDidMount() {
        let response = await playPlaylistVideo(this.study_id);
        this.state.videos = response.data;
        for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            if (element.viewed_at == null) {
                this.study_video_id = element.study_video_id;
                this.setState({
                    infoData: element,
                    videoAvailable: true,
                    isLoading: false,
                    processing: false
                })
                break;
            }
        }
        if (this.state.videoAvailable) {
            this.loadVideo();
        } else {
            this.setState({
                videoAvailable: true,
                infoData: { viewed_at: true },
                isLoading: false,
                processing: false
            })
        }
    }

    loadVideo() {
        this.screenshots = [];
        if (this.state.infoData.viewed_at === null) {
            clearTimeout(autoLogout);
            this.setState({ videoAvailable: true });
            var req = new XMLHttpRequest();
            req.open('GET', this.state.infoData.path, true);
            req.responseType = 'blob';

            req.onprogress = function (e) {
                let percent = Math.round((e.loaded * 100) / e.total);
                this.setState({ loadedVideo: percent });
            }.bind(this)

            req.onload = function (e) {
                if (req.readyState === 4) {
                    if (req.status === 200) {
                        var vid = window.URL.createObjectURL(req.response);
                        this.setState({ urlVideo: vid });

                    }
                }
            }.bind(this);
            req.onerror = function () {
                // Error
            }

            req.send();
        }
    }

    _onProgress(e) {
        this.capture();
    }

    uploadWebcam(index) {
        if (typeof this.screenshots[index] != 'undefined') {

            let percent = Math.round((index * 100) / this.screenshots.length);
            this.setState({ loadedVideo: percent });
            webcamUpload(this.screenshots[index], index, this.state.infoData.study_video_id).then((response) => {
                index++;
                this.uploadWebcam(index);
            })
        } else {
            viewedVideo(this.state.infoData.study_video_id).then((response) => {
                if (this.state.current_video < this.state.videos.length - 1) {
                    this.state.current_video++;
                    const element = this.state.videos[this.state.current_video];
                    this.study_video_id = element.study_video_id;
                    this.setState({
                        infoData: element,
                        videoAvailable: true,
                        isLoading: false,
                        loadedVideo: 0,
                        processing: false,
                        iframeUrl: null,
                        urlVideo: null,
                        isPlaying: false,
                    });
                    this.loadVideo();
                } else {
                    if (this.state.infoData.redirect_complete) {
                        let url_redirect = this.state.infoData.redirect_complete;
                        if (this.state.infoData.max_views != null && this.state.infoData.redirect_full && this.state.infoData.total_views > this.state.infoData.max_views) {
                            url_redirect = this.state.infoData.redirect_full;
                        }
                        this.cleanUrl(url_redirect);
                    }
                    this.setState({
                        ended: true,
                        processing: false
                    });
                }
            });

        }
    }

    _onEnded() {
        if (this.state.current_video == this.state.videos.length - 1 && this.state.infoData.redirect_complete) {
            let url_redirect = this.state.infoData.redirect_complete;
            if (this.state.infoData.max_views != null && this.state.infoData.redirect_full && this.state.infoData.total_views > this.state.infoData.max_views) {
                url_redirect = this.state.infoData.redirect_full;
            }
            //this.cleanUrl(url_redirect, true);
        }

        if (this.state.infoData.time_seconds <= this.screenshots.length) {
            this.setState({
                processing: true,
                loadedVideo: 0
            })
            this.uploadWebcam(0);
        }
    }

    _startVideo() {
        console.log('_startVideo');
        start_at();
        this.setState({
            isPlaying: true
        })
    }
    _onUserMedia() {
        this.setState({
            canPlay: true,
        });
    }

    render() {

        const { infoData, isPlaying, ended, canPlay, processing, urlVideo, videoAvailable, isLoading, loadedVideo, iframeUrl } = this.state;
        return (
            isLoading ?
                <div>
                    <p className="text-center py-4">
                        <span className="fa fa-refresh fa-spin fa-4x"></span>
                    </p>
                </div> :

                <div>
                    <nav className="navbar fixed-top navbar-expand-lg" id="sectionsNav">
                        <div className="container-fluid">
                            <div className="navbar-translate mb-2 mt-2">
                                <img src="https://emotions.brandful.com.br/assets/img/logo-novo.png" height="55" alt="Brandful" />
                            </div>

                            <div className="collapse navbar-collapse hidden-print">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" target="_blank" href="https://emotions-ws.brandful.com.br/privacy">{translate('Termos de Uso', infoData['language'])}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>

                    <ol className="breadcrumb py-4">
                        <li className="breadcrumb-item font-weight-bold">{translate('Painelista', infoData['language'])} | {translate('Bem Vindo', infoData['language'])}</li>
                    </ol>
                    <div className="container-fluid">
                        <h3 className="pt-4" style={{ overflowWrap: "break-word" }}><strong>{infoData['name']}</strong></h3>

                        <div dangerouslySetInnerHTML={{ __html: translate('regras_participacao', infoData['language']) }}></div>
                    </div>
                    {videoAvailable && infoData['viewed_at'] === null ?
                        <div>
                            <div className="container-fluid" style={{ paddingBottom: '60px' }}>
                                {processing ? <div className="alert alert-warning text-center"><p className="pt-2">{translate('Processando vídeo', infoData['language'])}</p><p><i className="fa fa-refresh fa-spin"></i> {loadedVideo}%</p></div> : null}
                                {ended ? <div className="alert alert-success text-center"><i className="fa fa-check-circle-o"></i> {translate('Vídeo visualizado com sucesso.', infoData['language'])}</div> :
                                    (processing === false ? <div className="player-wrapper">
                                        <ReactPlayer className='react-player'
                                            playing={isPlaying}
                                            url={urlVideo}
                                            controls={true}
                                            playsinline={true}
                                            width='100%'
                                            onEnded={this._onEnded}
                                            onProgress={this._onProgress}
                                        />
                                        {isPlaying ? null :
                                            canPlay && urlVideo ?
                                                <button className="btn btn-play-video" onClick={() => { this._startVideo() }}><i className="fa fa-play-circle-o"></i></button> :
                                                <div className="loading-video">
                                                    <span className="fa fa-refresh fa-spin"></span>
                                                    <span className="percent">{loadedVideo}%</span>
                                                </div>
                                        }
                                    </div> : null)
                                }
                            </div>
                            {ended ? null :
                                <div className="webcam_pip">
                                    <Webcam
                                        audio={false}
                                        height={240}
                                        width={240}
                                        mirrored={true}
                                        screenshotFormat="image/jpeg"
                                        screenshotQuality={0.95}
                                        minScreenshotHeight={320}
                                        minScreenshotWidth={320}
                                        ref={this.setRef}
                                        onUserMedia={this._onUserMedia}
                                        videoConstraints={videoConstraints}
                                    />
                                </div>
                            }
                        </div>
                        :
                        <div className="container d-flex flex-column align-items-center justify-content-center" style={{ height: '70vh' }}>
                            {videoAvailable ?
                                <div className="alert alert-info text-center">{translate('Você já participou desse estudo', infoData['language'])}</div>
                                : <div className="alert alert-warning text-center">{translate('Vídeo indisponível no momento', infoData['language'])}</div>
                            }

                        </div>
                    }
                    <Footer />
                    {iframeUrl ? <iframe src={iframeUrl} width="0" height="0" className='d-none'></iframe> : null}
                </div>
        )
    }
}
