import axios from "axios";
var token = localStorage.getItem("bfToken");
const api = axios.create({
  baseURL: "https://emotions-ws.brandful.com.br/",
  //baseURL: "https://staging-emotions-ws.brandful.com.br/",
  //baseURL: "http://localhost:8001/",
});

if (token) {
  api.defaults.headers.common["Authorization"] = token;
}
export default api;
