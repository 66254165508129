import React, { Component } from "react";
import { connect } from "react-redux";
import { changeStudyData, getPaineis, getProducts } from "../../../actions/studiesActions";
import { bindActionCreators } from "redux";
import { translate } from "../../../components/Translate";
import api from "../../../api";


export class Study extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "painel_url": ""
    }
    this.onChange = this.onChange.bind(this);
    this.onChangePainel = this.onChangePainel.bind(this);
  }

  onChange(e) {
    let data = this.props.studyData;
    data[e.target.name] = e.target.value;
    this.props.changeStudyData(data);
    this.setState({ update: true });

  }

  async onChangePainel(e) {
    let data = this.props.studyData;
    data[e.target.name] = e.target.value;
    this.props.changeStudyData(data);

    if (data['url']) {
      let painel = await api.get(`painel/${data['painel_id']}`);
      data['url'] = data['url'].split("?")[0];

      this.setState({ "painel_url": "?" + painel.data.url_param + "=" });
    }
    this.setState({ update: true });
  }

  async componentDidMount() {
    await this.props.getPaineis();
    await this.props.getProducts();
    this.setState({ update: true });
  }

  render() {
    const { studyData } = this.props;
    const { painel_url } = this.state;
    return (
      <fieldset>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label>Nome</label>
            <input
              type="text"
              className="form-control text-uppercase"
              name="name"
              onChange={this.onChange}
              value={studyData["name"]}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label>Anunciante</label>
            <input
              type="text"
              className="form-control text-uppercase"
              name="advertiser"
              onChange={this.onChange}
              value={studyData["advertiser"]}
              required
            />
          </div>

          <div className="form-group col-md-3">
            <label>Data Limite</label>
            <input
              type="date"
              className="form-control text-uppercase"
              name="date_limit"
              onChange={this.onChange}
              value={studyData["date_limit"]}
            />
          </div>

          <div className="form-group col-md-3">
            <label>Tags</label>
            <input
              type="text"
              className="form-control text-uppercase"
              name="tags"
              onChange={this.onChange}
              value={studyData["tags"]}
            />
          </div>

          <div className="form-group col-md-3">
            <label>Limite de Participações</label>
            <input
              type="number"
              className="form-control"
              name="max_views"
              onChange={this.onChange}
              value={studyData["max_views"]}
            />
          </div>

          <div className="form-group col-md-3">
            <label>Produto</label>
            <select className="form-control" name="product_id" onChange={this.onChange} value={studyData['product_id']}>
              {
                this.props.products.map(product => {
                  return (
                    <option value={product.product_id} key={product.product_id}>{product.description}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="form-group col-md-3">
            <label>Painel</label>
            <select className="form-control" name="painel_id" onChange={this.onChangePainel} value={studyData['painel_id']}>
              {
                this.props.paineis.map(painel => {
                  return (
                    <option value={painel.painel_id} key={painel.painel_id}>{painel.description}</option>
                  )
                })
              }
            </select>
          </div>

          <div className="form-group col-md-3">
            <label>Idioma</label>
            <select className="form-control" name="language" onChange={this.onChange} value={studyData['language']}>
              <option value="">Escollha um idioma</option>
              <option value="pt-br">{translate('pt-br')}</option>
              <option value="en-us">{translate('en-us')}</option>
              <option value="es">{translate('es')}</option>
            </select>
          </div>


          {JSON.parse(localStorage.user).group_id == "1" &&
            <>
              {studyData["url"] &&
                <div className="form-group col-md-6">
                  <label>URL</label>
                  <div className="form-control">{studyData["url"]}{painel_url}</div>
                </div>
              }
            </>
          }
        </div>
      </fieldset>
    );
  }
}

const mapStateToProps = (store) => ({
  studyData: store.studies.current,
  paineis: store.studies.paineis,
  products: store.studies.products,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeStudyData, getPaineis, getProducts }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Study);
