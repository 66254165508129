import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ReactPlayer from 'react-player'
import Webcam from "react-webcam";
import { playVideo, webcamUpload, viewedVideo, eventVideo } from '../../actions/watchActions';
import "./styles_play.css";

const videoConstraints = {
    width: 320,
    height: 320,
    facingMode: "user"
};

export default class Play extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false,
            ended: false,
            canPlay: false,
            processing: false,
            urlVideo: null,
            isLoading: true,
            videoAvailable: false,
            loadedVideo: 0,
            iframeUrl: null,
            infoData: {}
        }
        if (this.props.study_video_id) {
            this.study_video_id = this.props.study_video_id
        }

        this.webcam = null;
        this.screenshots = [];
        this._onProgress = this._onProgress.bind(this);
        this._onEnded = this._onEnded.bind(this);
        this._onUserMedia = this._onUserMedia.bind(this);
    }

    setRef = webcam => {
        this.webcam = webcam;
    };

    capture = () => {
        const imageSrc = this.webcam.getScreenshot();
        if (imageSrc != null) {
            this.screenshots.push(imageSrc);
        }
    };

    cleanUrl(url, show_iframe = false) {
        let post = { "study_video_id": this.study_video_id };

        let tags = url.match(/({{[a-z_0-9]+}})/gi);
        let query_string = window.location.search.replaceAll("%3C", "").replaceAll("%3E", "").replaceAll('(', '').replaceAll(')', '');
        let params = new URLSearchParams(query_string);
        for (var pair of params.entries()) {
            post[pair[0]] = pair[1];
        }

        tags.map((val) => {
            url = url.replace(val, params.get(val.match(/[a-z_0-9]+/)[0]));
        });
        const redirect_url = new URL(url)

        let searchParams = new URLSearchParams(redirect_url.search);

        if (show_iframe) {
            console.log(url);
            this.setState({
                iframeUrl: url,
            });
        } else {

            for (var pair of searchParams.entries()) {
                post[pair[0]] = pair[1];
            }
            eventVideo(post).then(() => {
                window.location.href = url;
            });
        }
    }

    async componentDidMount() {
        let response = await playVideo();
        this.setState({ infoData: response.data, isLoading: false });
        if (response.data != null && response.data.viewed_at === null) {
            this.setState({ videoAvailable: true });
            var req = new XMLHttpRequest();
            req.open('GET', response.data.path, true);
            req.responseType = 'blob';

            req.onprogress = function (e) {
                let percent = Math.round((e.loaded * 100) / e.total);
                this.setState({ loadedVideo: percent });
            }.bind(this)

            req.onload = function (e) {
                if (req.readyState === 4) {
                    if (req.status === 200) {
                        var vid = window.URL.createObjectURL(req.response); // IE10+
                        this.setState({ urlVideo: vid });
                    }
                }
            }.bind(this)
            req.onerror = function () {
                // Error
            }

            req.send();
        }
    }

    _onProgress(e) {
        this.capture();
    }

    uploadWebcam(index) {
        if (typeof this.screenshots[index] != 'undefined') {

            let percent = Math.round((index * 100) / this.screenshots.length);
            this.setState({ loadedVideo: percent });
            webcamUpload(this.screenshots[index], index).then((response) => {
                index++;
                this.uploadWebcam(index);
            })
        } else {
            viewedVideo().then((response) => {
                if (this.state.infoData.redirect_complete) {
                    let url_redirect = this.state.infoData.redirect_complete;
                    if (this.state.infoData.max_views != null && this.state.infoData.redirect_full && this.state.infoData.total_views > this.state.infoData.max_views) {
                        url_redirect = this.state.infoData.redirect_full;
                    }
                    this.cleanUrl(url_redirect);
                }
                this.setState({
                    ended: true,
                    processing: false
                });
            });

        }
    }

    _onEnded() {
        if (this.state.infoData.time_seconds <= this.screenshots.length) {
            this.setState({
                processing: true,
                loadedVideo: 0
            })
            this.uploadWebcam(0)
        }
        if (this.state.infoData.redirect_complete) {
            let url_redirect = this.state.infoData.redirect_complete;
            if (this.state.infoData.max_views != null && this.state.infoData.redirect_full && this.state.infoData.total_views > this.state.infoData.max_views) {
                url_redirect = this.state.infoData.redirect_full;
            }
            this.cleanUrl(url_redirect, true);
        }

    }

    _startVideo() {
        this.setState({
            isPlaying: true
        })
    }
    _onUserMedia() {
        this.setState({
            canPlay: true,

        })
    }

    render() {
        const { infoData, isPlaying, ended, canPlay, processing, urlVideo, videoAvailable, isLoading, loadedVideo, iframeUrl } = this.state;
        return (
            isLoading ?
                <div>
                    <p className="text-center py-4">
                        <span className="fa fa-refresh fa-spin fa-4x"></span>
                    </p>
                </div> :

                <div>
                    <nav className="navbar fixed-top navbar-expand-lg" id="sectionsNav">
                        <div className="container-fluid">
                            <div className="navbar-translate mb-2 mt-2">
                                <img src="/assets/img/logo-netquest.png" height="55" alt="Brandful" />
                            </div>

                            <div className="collapse navbar-collapse hidden-print">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <a className="nav-link" target="_blank" href="https://emotions-ws.brandful.com.br/privacy">Termos de Uso</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </nav>

                    <ol className="breadcrumb py-4">
                        <li className="breadcrumb-item font-weight-bold">Painelista | Bem Vindo</li>
                    </ol>
                    <div className="container-fluid">
                        <h3 className="pt-4" style={{ overflowWrap: "break-word" }}><strong>{infoData['name']}</strong></h3>

                        <p>Você está prestes a participar de uma pesquisa de análise por meio do monitoramento da sua face e reconhecimento de suas emoções. Para que tudo dê certo, basta que assista ao vídeo descrito abaixo da forma mais natural possível, seguindo os seguintes passos:</p>
                        <ul className="list-unstyled text-muted font-weight-bold">
                            <li>Passo 1: Verifique se o ambiente está bem iluminado e sua webcam está em correto funcionamento.</li>
                            <li>Passo 2: Clique sobre o botão iniciar abaixo para começar a pesquisa.</li>
                            <li>Passo 3: Autorize a ativação da sua webcam para identificação inicial.</li>
                            <li>Passo 4: Assista o comercial até o fim.</li>
                            <li>Passo 5: Aguarde o processamento e a mensagem de sucesso.</li>
                        </ul>
                        <p className="pt-4 pb-8"><strong>*Importante:</strong> Sua webcam será utilizada apenas durante a exibição do vídeo.</p>
                    </div>
                    {videoAvailable && infoData['viewed_at'] === null ?
                        <div>
                            <div className="container-fluid" style={{ paddingBottom: '60px' }}>
                                <h3 className="pt-4" style={{ overflowWrap: "break-word" }}><strong>{infoData['name']}</strong></h3>
                                {processing ? <div className="alert alert-warning text-center"><p className="pt-2">Processando vídeo</p><p><i className="fa fa-refresh fa-spin"></i> {loadedVideo}%</p></div> : null}
                                {ended ? <div className="alert alert-success text-center"><i className="fa fa-check-circle-o"></i> Vídeo visualizado com sucesso.</div> :
                                    (processing === false ? <div className="player-wrapper">
                                        <ReactPlayer className='react-player'
                                            playing={isPlaying}
                                            url={urlVideo}
                                            controls={false}
                                            playsinline={true}
                                            width='100%'
                                            onEnded={this._onEnded}
                                            onProgress={this._onProgress}
                                            onPlay={this._onPlay} />
                                        {isPlaying ? null :
                                            canPlay && urlVideo ?
                                                <button className="btn btn-play-video" onClick={() => { this._startVideo() }}><i className="fa fa-play-circle-o"></i></button> :
                                                <div className="loading-video">
                                                    <span className="fa fa-refresh fa-spin"></span>
                                                    <span className="percent">{loadedVideo}%</span>
                                                </div>
                                        }
                                    </div>
                                        : null)
                                }
                            </div>
                            {ended ? null :
                                <div className="webcam_pip">
                                    <Webcam
                                        audio={false}
                                        height={240}
                                        width={240}
                                        mirrored={true}
                                        screenshotFormat="image/jpeg"
                                        screenshotQuality={0.95}
                                        minScreenshotHeight={320}
                                        minScreenshotWidth={320}
                                        ref={this.setRef}
                                        onUserMedia={this._onUserMedia}
                                        videoConstraints={videoConstraints}
                                    />
                                </div>
                            }
                        </div>
                        :
                        <div className="container d-flex flex-column align-items-center justify-content-center" style={{ height: '70vh' }}>
                            {videoAvailable ?
                                <div className="alert alert-info text-center">Você já visualizou esse vídeo</div>
                                : <div className="alert alert-warning text-center">Vídeo indisponível no momento</div>
                            }

                        </div>
                    }
                    <Footer />
                    {iframeUrl ? <iframe src={iframeUrl} width="0" height="0" className='d-none'></iframe> : null}
                </div>
        )
    }
}
